
import { Options, Prop, Vue, Watch } from "vue-property-decorator";

@Options({})
export default class extends Vue {
  @Prop({ required: true })
  private options: any;

  private aliplayer: any;

  private player: any;

  @Watch("options", { deep: true })
  private onOptionsChange(): void {
    if (this.aliplayer) {
      this.aliplayer.dispose();
      setTimeout(() => {
        this.createAliplayer();
      }, 1000);
    }
  }

  mounted(): void {
    this.createAliplayer();
  }

  beforeUnmount(): void {
    if (this.aliplayer) {
      this.aliplayer.dispose();
    }
  }


  private createAliplayer(): void {
    this.aliplayer = new Aliplayer(
      {
        id: "J_prismPlayer",
        autoplay: true,
        mute: true,
        ...this.options,
      },
      (player: any) => {
        player.setVolume(0); //音乐调到零
        document.addEventListener('WeixinJSBridgeReady', () =>{
          if(player.tag)player.tag.play()
        })
        player.play();
        this.player = player
        this.$emit("aliplayerReady", player);
      }
    );
    setTimeout(() => {
      var videoElement = document.getElementById(
        "J_prismPlayer"
      ) as HTMLMediaElement;
      videoElement.addEventListener("touchstart", function (e: any) {
        console.log(e);
        
      })
      var evet = new MouseEvent('touchstart',{
        view: window,
        bubbles: true,
        cancelable: true,
        clientX: 77,
        clientY: 185
        })
        videoElement.dispatchEvent(evet);
    }, 3000);
  }
}
